import { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import styled from "styled-components";
import { Paragraph } from "../styles/text.style";
import { SecondaryButton } from "./Button";
import { ReactComponent as Camera } from "../assets/svg/camera.svg";

const FU = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 305px;
  border: 1px dashed rgba(0, 0, 0, 0.58);

  .file {
    width: 100%;
    height: 280px;
    position: relative;
    overflow: hidden;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      scale: 1;
    }
  }
`;

const fileTypes = ["JPG", "PNG", "WEBP"];
function ImageUploader({ setImg }) {
  const [file, setFile] = useState(null);
  const [file64, setFile64] = useState(null);

  const handleChange = (file) => {
    setFile(file);
  };

  const handleRefresh = (e) => {
    e.preventDefault();
  };

  // const handleRemove = e => {
  //   e.preventDefault();
  //   setFile(null);
  // };

  //preview file from input as thumbnail react
  const previewFile = (file) => {
    // if (typeof file === Array) {

    //   const reader = new FileReader();
    //   file.map(f => {
    //     reader.readAsDataURL(f);
    //     reader.onloadend = () => {
    //       setFile64(prev => [...prev, reader.result]);
    //     };

    //   };
    // }
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setFile64(reader.result);
        setImg(reader.result);
      };
    }
  };

  return (
    <FileUploader
      handleChange={handleChange}
      name="file"
      types={fileTypes}
      // multiple={true}
    >
      <FU>
        {file ? (
          <>
            {previewFile(file)}
            <div className="file">
              <img src={file64 && file64} alt="file" />
            </div>
            {file.name}
            {/* <img src={Close} alt="close" onClick={handleRemove} /> */}
          </>
        ) : (
          <>
            <Camera />
            <Paragraph align="center">
              Click here or drag and drop to upload a photo.
            </Paragraph>
            <SecondaryButton
              justify="center"
              onClick={handleRefresh}
              text="Choose Photo"
            >
              Add license photo
            </SecondaryButton>
          </>
        )}
      </FU>
    </FileUploader>
  );
}

export default ImageUploader;
