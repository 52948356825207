import { useCallback, useContext, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import OTP from "react-otp-input";
import { WithoutLayout } from "../../components/Layout";
import { Header1, Header3, Paragraph } from "../../styles/text.style";
import { ReactComponent as Search } from "../../assets/svg/search.svg";
import { MainButton } from "../../components/Button";
import { StoreContext } from "../../store/context/store.context";
import { observer } from "mobx-react-lite";
import toast from "react-hot-toast";
// import { addCountryCode } from "../../utils/addCountryCode";

const OtpStyle = styled(OTP)`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 10%;
  border-radius: 0;
  margin: 0 auto;
  padding: 0 3rem;
  max-width: 309.008px;
  padding-bottom: 1rem;
  @media screen and (min-width: 768px) {
    max-width: 500px;
  }

  .inputStyle {
    min-height: 3rem;
    height: 5rem;
    width: 8rem;
    font-size: 3.5rem;
    border: none;
    border-radius: 0;
    border-bottom: solid #000;
    outline: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
  }
`;

const Para = styled(Paragraph)`
  font-weight: 600;

  color: ${({ theme }) => theme.colors.primary};
`;

const VerifyOtp = () => {
  const [otp, setOtp] = useState();
  const [loading, setLoading] = useState(false);

  const handleChange = (newOtp) => setOtp(newOtp);
  const navigate = useNavigate();

  const { authStore } = useContext(StoreContext);

  const resendOTP = useCallback(async () => {
    try {
      setLoading(true);
      await authStore.sendOTP(authStore.getAuthData.phone);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  }, [authStore]);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        if (!otp) return null;

        if (!authStore.getAuthData.phone) return toast.error("! went wrong");
        setLoading(true);
        authStore.verifyOTP(otp, authStore.getAuthData.phone).then((data) => {
          setLoading(false);
          data.status ? navigate("/signup") : toast.error(data.message);
        });
      } catch (error) {
        setLoading(false);
        toast.error(error.response.data.message);
      }
    },
    [otp, authStore, navigate]
  );

  return (
    <WithoutLayout>
      <Header1 align="center">Enter verification code</Header1>
      <Header3 align="center">
        Verify your account by typing in the 4 digit code sent to you via text.
      </Header3>
      <form onSubmit={handleSubmit}>
        <OtpStyle
          onChange={handleChange}
          value={otp}
          isInputNum={true}
          numInputs={4}
          containerStyle={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            margin: "0px auto",
            padding: "0 3rem",
            maxWidth: "309.008px",
            paddingTop: "2rem",
          }}
          inputStyle="inputStyle"
        />
        <Header3 align="center">
          We sent a code to the following phone number:
        </Header3>
        <div
          className="otp-ctrl"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <Para>{`${authStore.getAuthData.phone.slice(
            3,
            6
          )} **** ${authStore.getAuthData.phone.slice(10)}`}</Para>
          <Para
            as="p"
            onClick={resendOTP}
            style={{
              cursor: "pointer",
            }}
          >
            Resend Code
          </Para>
        </div>

        {/* <button type="submit">dd</button> */}
        <MainButton loading={loading} text="Next" />
      </form>
      <Header3 align="center">Problems receiving the code?</Header3>
      <div
        className="support"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "4px",
        }}
      >
        <Search
          style={{
            fill: "hsla(232, 75%, 25%, 1)",
            width: "16px",
            height: "16px",
          }}
        />
        <Para>Support center</Para>
      </div>
    </WithoutLayout>
  );
};

export default observer(VerifyOtp);
