import React from "react";
import { getCarStatus } from "../../utils/getCarStatus";
import WithLayout from "./WithLayout";
import WithoutLayout from "./WithoutLayout";

const CheckLayout = Component => {
  const hasCar = getCarStatus();

  // console.log(hasCar);
  class CheckLayout extends React.Component {
    render() {
      if (hasCar === null || hasCar === undefined)
        return console.log("hasCar is null");

      return hasCar ? (
        <WithLayout>
          <Component />
        </WithLayout>
      ) : (
        <WithoutLayout>
          <Component />
        </WithoutLayout>
      );
    }
  }

  return CheckLayout;
};

export { WithLayout, WithoutLayout, CheckLayout };
