import React from "react";
import { Toaster } from "react-hot-toast";
import { ThemeProvider } from "styled-components";

import AllRoutes from "./utils/routes.util";

const Theme = {
  colors: {
    primary: "#101D70",
    white: "#fff",
    grey_background: "#F7F7F7",
    black: "#000",
  },
};

function App() {
  React.useEffect(() => {
    window.Intercom("boot", {
      api_base: "https://api-iam.intercom.io",
      app_id: "pdfewggu",
    });
    window.Intercom("update");
  });

  return (
    <div className="App">
      <ThemeProvider theme={Theme}>
        <AllRoutes />
        <Toaster
          position="bottom-center"
          reverseOrder={true}
          toastOptions={{
            // Define default options
            className: "",
            duration: 10000,
            style: {
              fontSize: "14px",
            },
            // Default options for specific types
          }}
        />
      </ThemeProvider>
    </div>
  );
}

export default App;
