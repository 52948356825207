import React, { useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import { Header1, Paragraph } from "../styles/text.style";
import { Grid, GridItem } from "../styles/layout.style";
import { HomeStyle } from "../styles/home.style";
import { SecondaryButton } from "../components/Button";
import MoreInfo from "../components/MoreInfo";
import useCheckStatusAndNavigate from "../utils/useCheckStatusAndNavigate.hook";
import { StoreContext } from "../store/context/store.context";
import { getResumePage } from "../utils/resumeMap.util";
import gsap from "gsap";

const Wb = styled(HomeStyle)`
  min-height: min-content;
  /* height: 60%; */

  main {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const Pad = styled.div`
  padding: 0 1.25rem;
`;

function WelcomeBack() {
  const navigate = useNavigate();
  const checkStatusAndNavigate = useCheckStatusAndNavigate([
    "/questionnaire",
    "/location-preference",
  ]);
  const { driverStore } = React.useContext(StoreContext);

  const name = driverStore.getDriverData.firstName;

  const logout = async () => {
    await driverStore.clearStoredData().then(() => {
      navigate("/");
    });
  };

  const restartReg = async () => {
    // await driverStore.clearStoredData().then(() => {
    //   checkStatusAndNavigate();
    // });
    driverStore.setDriverStatus = { resumePage: null };
    checkStatusAndNavigate();
  };

  const handleResume = () => {
    driverStore.getDriverStatus.resumePage
      ? navigate(getResumePage(driverStore.getDriverStatus.resumePage))
      : checkStatusAndNavigate();
  };

  //gsap
  useEffect(() => {
    gsap.fromTo(
      ".welcome-back-text",
      {
        opacity: 0,
        y: -5,
      },
      {
        opacity: 1,
        y: 0,
        delay: 1,
        stagger: 0.2,
      }
    );
    gsap.fromTo(
      ".welcome-back",
      {
        height: "150%",
        duration: 3,
        ease: "power4.inOut",
      },
      {
        opacity: 1,
        height: "60%",
      }
    );
  }, []);

  return (
    <>
      <Wb className="welcome-back">
        <Navbar inverse />
        <main className="welcome-back-text">
          <Grid>
            <GridItem span={2} />

            <GridItem span={8}>
              <Pad>
                <Header1 inverse align="center" className="welcome-back-text">
                  Welcome Back, <br /> {name} 👋🏽
                </Header1>
                <SecondaryButton
                  inverse
                  text="Continue Application"
                  justify="center"
                  onClick={handleResume}
                />

                <Paragraph
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    display: "block",
                  }}
                  inverse
                  onClick={restartReg}
                  align="center"
                >
                  Start a new application here
                </Paragraph>
                <Paragraph
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    display: "block",
                  }}
                  inverse
                  onClick={logout}
                  align="center"
                >
                  Log out
                </Paragraph>
              </Pad>
            </GridItem>
            <GridItem span={2} />
          </Grid>
        </main>
      </Wb>
      <MoreInfo />
    </>
  );
}

export default WelcomeBack;
