import styled from "styled-components";

export const HomeStyle = styled.div`
  background: ${({ theme }) => theme.colors.primary};
  /* opacity: 0; */
  /* min-height: 100vh; */
  /* min-height: -webkit-fill-available; */
  /* max-height: 1024px !important; */
  /* height: 100%; */
  position: relative;
  padding: 40px 0;
  border-radius: 0 0 40px 40px;

  main {
    /* position: absolute; */
    top: 45px;
    left: 0;
    right: 0;
    /* bottom: 0; */
    /* overflow: hidden; */
    /* overflow-y: auto; */
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    justify-content: center;

    @media screen and (min-width: 768px) {
      top: 50px;
      align-items: center;
    }
  }
`;
