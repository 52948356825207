import React from "react";
import {
  RadioGroup,
  Radio as RadioElement,
  useRadioGroup,
} from "react-radio-input";
import styled from "styled-components";

export const RadioGroupContainer = styled(RadioGroup)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Label = styled.label(
  ({ isSelected }) => `
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 0.778rem;
  gap: 8px;

  input:checked {
    background-color: ${isSelected ? "#101d70" : "red"};
  }
`
);

function Radio({ value = "", label = "" }) {
  const { selectedValue } = useRadioGroup();
  const isSelected = value === selectedValue;
  // console.log(isSelected, selectedValue, value, "hw fr");
  return (
    <Label isSelected={isSelected}>
      <RadioElement value={value} />
      <span>{label}</span>
    </Label>
  );
}

export default Radio;
