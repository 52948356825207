export function addCountryCode(phone) {
  if (phone.length > 9 && phone[0] === "0") {
    return addCountryCode(phone.slice(1));
  }

  if (phone.slice(0, 3) === "234") return phone;

  switch (phone.length) {
    case 10:
      return "234" + phone;

    case 11:
      return "234" + phone.slice(1);

    case 12:
      return "234" + phone.slice(2);

    case 13:
      return "234" + phone.slice(3);

    default:
      return phone;
  }
}

export function addPhoneSpace(phone) {
  if (!phone) return null;
  if (phone.length < 10) return phone;

  return phone.slice(0, 3) + phone.slice(3);
  // return phone.slice(0, 3) + " " + phone.slice(3);
}

export function removeSpace(phone) {
  return phone.replace(/ /g, "");
}
