import React, { useState, useEffect, useContext } from "react";
import * as yup from "yup";
import { MainButton } from "../components/Button";
import { Input } from "../components/InputFields";
import useYupValidation from "../utils/useYupValidation.hook";
import { Header1, Header3, Paragraph } from "../styles/text.style";
import useCheckStatusAndNavigate from "../utils/useCheckStatusAndNavigate.hook";
import { StoreContext } from "../store/context/store.context";

function Nin() {
  const checkStatusAndNavigate = useCheckStatusAndNavigate("/document-upload");

  const { driverStore } = useContext(StoreContext);

  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    nin: "",
  });

  const formSchema = yup.object().shape({
    nin: yup.string().min(11).max(11).required(),
  });

  const { onSubmit, onFieldChange, errors, reValidate } = useYupValidation(
    formSchema,
    setValues,
    values
  );

  useEffect(() => {
    reValidate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const handleSubmit = e => {
    if (!values) {
      return null;
    }

    setLoading(true);
    driverStore.setDriverData = values;
    checkStatusAndNavigate();
    setLoading(false);
  };
  return (
    <form onSubmit={e => onSubmit(e, handleSubmit)}>
      <Header1>Driver Information</Header1>

      <Header3>Your National Identification Number (NIN)</Header3>
      <Paragraph>
        Your NIN will be used to confirm your citizenship, residential address
        and other relevant information. Please ensure that all records
        associated with your NIN are up to date.
      </Paragraph>
      <div className="input-group">
        <Input
          label="Nin"
          type="tel"
          name="nin"
          onChange={onFieldChange}
          value={values.nin}
          error={errors.nin}
          autoComplete="off"
        />
      </div>

      <MainButton justify="flex-start" text="Next" loading={loading} />
    </form>
  );
}

export default Nin;
