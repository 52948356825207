import { useState, useContext, useEffect } from "react";
import { Input } from "../components/InputFields";
import Navbar from "../components/Navbar";
import * as yup from "yup";
import useYupValidation from "../utils/useYupValidation.hook";
import RadioButton, {
  RadioGroupContainer,
} from "../components/Button/RadioButton";
import { Header1, Paragraph, Header3 } from "../styles/text.style";
import { Grid, GridItem, Container } from "../styles/layout.style";
import { HomeStyle } from "../styles/home.style";
import { MainButton } from "../components/Button";
import MoreInfo from "../components/MoreInfo";
import { useNavigate } from "react-router-dom";
import { StoreContext } from "../store/context/store.context";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { Navigate } from "react-router-dom";
import { addCountryCode } from "../utils/editPhone.util";
import getAuthToken from "../utils/getAuthToken";
import gsap from "gsap";

function Home() {
  const [hasCar, setHasCar] = useState(true);
  const [loading, setLoading] = useState(false);
  const { authStore } = useContext(StoreContext);
  const navigate = useNavigate();

  const [values, setValues] = useState({
    phone: "",
    password: "",
    hasCar,
  });
  // const phoneRegExp =
  // /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const formSchema = yup.object().shape({
    // phone: yup.string().matches(phoneRegExp, "Phone number is not valid"),
    phone: yup.string().min(10).max(16).trim().required(),
    password: yup.string().min(6).required(),
    hasCar: yup.boolean().required(),
  });

  const { onSubmit, onFieldChange, errors, reValidate } = useYupValidation(
    formSchema,
    setValues,
    values
  );

  useEffect(() => {
    reValidate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  //gsap
  useEffect(() => {
    gsap.fromTo(
      ".home-text",
      {
        opacity: 0,
        y: 10,
      },
      {
        opacity: 1,
        y: 0,
        delay: 2,
        stagger: 0.2,
      }
    );
    gsap.fromTo(
      ".home",
      {
        // height: "40%",
        opacity: 0,
        duration: 3,
        ease: "power4.inOut",
      },
      {
        opacity: 1,

        // height: "100%",
      }
    );
  }, []);

  //chng
  useEffect(() => {
    setValues(prev => ({
      ...prev,
      phone: addCountryCode(prev.phone).trim(),
    }));
  }, [values.phone]);

  function handleSubmit() {
    if (!values) {
      return null;
    }
    setLoading(true);
    authStore
      .sendOTP(values.phone)
      .then(() => (authStore.setAuthData = values))
      .then(data => {
        setLoading(false);
        data && navigate("/verify");
      })
      .then(() => toast.success("OTP sent successfully"))
      .catch(err => {
        toast.error("Something went wrong. Try again");
        setLoading(false);
      });
  }

  return getAuthToken() ? (
    <Navigate to="/welcome-back" />
  ) : (
    <>
      <HomeStyle className="home">
        <Navbar inverse className="home-text" />
        <main>
          <Container
            percentage
            as="form"
            onSubmit={e => onSubmit(e, handleSubmit)}
          >
            <Header3 inverse className="home-text">
              HELLO, FUTURE DRIVER
            </Header3>
            <Header1 inverse marginY="12px" className="home-text">
              Drive towards your dreams
            </Header1>
            <Header3 inverse marginY="24px" className="home-text">
              Sign up to drive
            </Header3>
            <RadioGroupContainer
              name="adCtrl"
              onChange={setHasCar}
              selectedValue={hasCar}
              component="div"
              className="home-text"
            >
              <RadioButton label="I have a car" value={true} />
              <RadioButton label="I need a car" value={false} />
            </RadioGroupContainer>
            <Grid>
              <GridItem span={8} className="home-text">
                <Input
                  label="Phone Number"
                  type="tel"
                  name="phone"
                  onChange={onFieldChange}
                  value={values.phone}
                  error={errors.phone}
                  autoComplete="off"
                  // marginY="12px"
                  inverse
                />
              </GridItem>
              <GridItem span={12 - 6} />
              <GridItem span={8} className="home-text">
                <Input
                  label="Password"
                  type="password"
                  name="password"
                  onChange={onFieldChange}
                  value={values.password}
                  error={errors.password}
                  autoComplete="off"
                  // marginY="12px"
                  inverse
                />
              </GridItem>
            </Grid>
            <MainButton
              inverse
              justify="flex-start"
              text="Next"
              loading={loading}
              className="home-text"
            />
            <Header3 inverse className="home-text">
              Sign in to drive
            </Header3>
            <Paragraph
              inverse="false"
              as={Link}
              to="/login"
              className="home-text"
            >
              Login
            </Paragraph>
          </Container>
        </main>
      </HomeStyle>
      <MoreInfo />
    </>
  );
}

export default observer(Home);
