/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect } from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { MainButton } from "../components/Button";
import { Radio } from "../components/InputFields";
import { RadioGroupContainer } from "../components/InputFields/Radio";
import getAuthToken from "../utils/getAuthToken";
import toast from "react-hot-toast";

import { Header2, Header3, Paragraph } from "../styles/text.style";
import useCheckStatusAndNavigate from "../utils/useCheckStatusAndNavigate.hook";

const dataList = [
  {
    id: 1,
    questionType: "multiselect",
    question: "Why do you choose to drive with Bosscab?",
    answers: [
      {
        id: uuidv4(),

        text: "I don't have a vehicle and Bosscab is providing one",
      },
      {
        id: uuidv4(),

        text: "I'm in it for the technology",
      },
      {
        id: uuidv4(),

        text: "Instant and flexible earning opportunity",
      },
      {
        id: uuidv4(),

        text: "To meet new people and create a connection with them",
      },
      {
        id: uuidv4(),
        text: "To put myself through school and afford other bills",
      },
      {
        id: uuidv4(),

        text: "Other, please specify",
      },
    ],
  },
  {
    id: 2,
    questionType: "single",
    question: "How many hours are you looking to drive each week ?",
    answers: [
      {
        id: uuidv4(),
        text: "Less than 10 hours",
      },
      {
        id: uuidv4(),

        text: "10 to 20 hours",
      },
      {
        id: uuidv4(),

        text: "20 to 40 hours",
      },
      {
        id: uuidv4(),

        text: "40 to 60 hours",
      },
      {
        id: uuidv4(),
        text: "More than 60 hours",
      },
    ],
  },
];

const QN = styled.div`
  .questions-list {
    margin-top: 30px;
  }

  .question {
    margin-top: 3rem;
  }
  .answers-list {
    margin: 10px 0;
    border-top: #c4c4c4 1px solid;

    label {
      padding: 16px 0;
      border-bottom: 1px solid #00000017;
      width: 90%;

      @include media(">=768px") {
        padding: 30px 0;
      }

      &:first-of-type {
        border-top: none;
      }
      &:last-of-type {
        border-bottom: none;
      }
    }
  }
`;

function Questionnaire() {
  const [selectedAnswers, setSelectedAnswers] = React.useState([]);
  const [questions, setQuestions] = React.useState(
    "What is the name of your brand"
  );
  const [a, setA] = React.useState("");
  const checkStatusAndNavigate = useCheckStatusAndNavigate(
    "/vehicle-preference"
  );

  // useEffect(() => {
  //   fetch(`${process.env.REACT_APP_BOSSCAB_API_URL}/questionnaires`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: getAuthToken(),
  //     },
  //     body: JSON.stringify({
  //       question: questions,
  //     }),
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       console.log(data, "checkkkk");
  //       // setQuestions(data);
  //     })
  //     .catch((err) => toast.error(err));
  // }, []);

  console.log(questions, "how far");

  const handleChange = (answer, questionId) => {
    console.log(answer, questionId);
    const newAnswer = {
      questionId: questionId,
      answer: answer,
    };
    setSelectedAnswers((prev) => [...prev, newAnswer]);
  };

  return (
    <QN>
      <Header2>Tell us about yourself</Header2>
      <div className="questions-list">
        {dataList.map((data) => (
          <Fragment key={uuidv4()}>
            <div className="question">
              <Header3>{data.question}</Header3>
            </div>
            {data.questionType === "multiselect" && (
              <span className="instructions">
                <Paragraph>Please select one or more options</Paragraph>
              </span>
            )}
            <div className="answers-list" key={uuidv4()}>
              <RadioGroupContainer
                name={uuidv4()}
                onChange={(e) => {
                  setA(e);
                  console.log(a);
                }}
                selectedValue={a}
                component="div"
              >
                {data.answers.map((answer) => (
                  <Radio
                    label={answer.text}
                    value={answer.text}
                    key={uuidv4()}
                  />
                ))}
              </RadioGroupContainer>
            </div>
          </Fragment>
        ))}
      </div>
      <MainButton text="Next" onClick={checkStatusAndNavigate} />
    </QN>
  );
}

export default Questionnaire;
