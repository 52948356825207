import { useState, useEffect } from "react";
import styled from "styled-components";
import { MainButton } from "../../components/Button";
import { Select } from "../../components/InputFields";
import { Header1, Paragraph } from "../../styles/text.style";
import useCheckStatusAndNavigate from "../../utils/useCheckStatusAndNavigate.hook";
import getAuthToken from "../../utils/getAuthToken";
import toast from "react-hot-toast";

const ADV = styled.form`
  .input-group {
    margin-top: 10px;

    .select-wrapper {
      border-top: 1px solid #efefef;
      padding: 8px 0;
    }

    .select-wrapper:first-of-type {
      border-top: none;
    }
  }
`;

function AddVehicle() {
  const checkStatusAndNavigate = useCheckStatusAndNavigate("/nin");
  const res = JSON.parse(localStorage.getItem("driver_persist"));

  const [vehicleData, setVehicleData] = useState({
    year: "",
    manufacturer: "",
    model: "",
    color: "",
    doors: "",
    seatbelts: "",
  });

  const [initialData, setInitialData] = useState({
    years: [],
    doors: [],
    seatbelts: [],
    model: [],
  });

  const [manufacturers, setManufacturers] = useState([]);
  const [models, setModels] = useState([]);

  useEffect(() => {
    fetch("/addVehicleData.json")
      .then((res) => res.json())
      .then((data) => {
        setInitialData(data);
      })
      .catch((err) => toast.error(err));

    fetch(`${process.env.REACT_APP_BOSSCAB_API_URL}/driver/vehicle/brand/get`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthToken(),
      },
      body: JSON.stringify({
        companyId: process.env.REACT_APP_COMPANY_ID,
        driverId: res?.data?.id,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setManufacturers(data);
      })
      .catch((err) => toast.error(err));

    fetch(`${process.env.REACT_APP_BOSSCAB_API_URL}/driver/vehicle/model/get`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthToken(),
      },
      body: JSON.stringify({
        companyId: process.env.REACT_APP_COMPANY_ID,
        driverId: res?.data?.id,
        brandId: vehicleData?.manufacturer,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setModels(data);
      })
      .catch((err) => toast.error(err));
  }, [res?.data?.id, vehicleData?.manufacturer]);

  const newOptionBrand = manufacturers?.vehicleBrand?.map((item) => ({
    value: item?.vehicleBrandId,
    label: item?.vehicleBrandName,
  }));

  const newOptionModel = models?.vehicleModel?.map((item) => ({
    value: item?.vehicleModelId,
    label: item?.vehicleModelName,
  }));

  const handleSubmit = (e) => {
    e.preventDefault();
    checkStatusAndNavigate();
  };

  const vehicleOptions = [
    {
      name: "Year",
      options: initialData.years,
    },
    {
      name: "Manufacturer",
      options: newOptionBrand,
    },
    {
      name: "Model",
      options: newOptionModel,
    },
    {
      name: "Color",
      options: [
        { value: "red", label: "Red" },
        { value: "black", label: "Black" },
        { value: "blue", label: "Blue" },
        { value: "yellow", label: "Yellow" },
        { value: "white", label: "White" },
        { value: "green", label: "Green" },
        { value: "grey", label: "Grey" },
        { value: "gold", label: "Gold" },
        { value: "silver", label: "Silver" },
      ],
    },
    {
      name: "Doors",
      options: initialData.doors,
    },
    {
      name: "Seatbelts",
      options: initialData.seatbelts,
    },
  ];

  return (
    <ADV onSubmit={handleSubmit}>
      <Header1>Add your vehicle</Header1>
      <div className="input-group">
        {vehicleOptions.map((vehicleOption) => (
          <div className="select-wrapper" key={vehicleOption.name}>
            <Paragraph>{vehicleOption.name}</Paragraph>
            <Select
              options={vehicleOption.options}
              onChange={(newValue, actionMeta) =>
                setVehicleData({
                  ...vehicleData,
                  [vehicleOption.name.toLowerCase()]: newValue.value,
                })
              }
              placeholder={`select ${vehicleOption.name}`}
            />
          </div>
        ))}
      </div>
      <MainButton text="Next" />
    </ADV>
  );
}

export default AddVehicle;
