import React from "react";
import { SecondaryButton } from "../components/Button";
import { Header2, Header3, Paragraph } from "../styles/text.style";
import { ReactComponent as Car } from "../assets/svg/vehiclePrefCar.svg";
import styled from "styled-components";
import useCheckStatusAndNavigate from "../utils/useCheckStatusAndNavigate.hook";

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 40px;
  padding: 32px;
  background: #ffffff;
  box-shadow: 0px 1px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
  overflow: hidden;
  margin: 20px 0;
  gap: 20px;

  .car {
    display: none;
  }

  @media screen and (min-width: 768px) {
    .car {
      display: flex;
    }
  }
`;

const FlexItem = styled.div`
  display: ${props => (props.flex ? "flex" : "block")};
  align-items: center;
  justify-content: center;
`;

function VehiclePreference() {
  const driver = {
    hasCar: true,
  };
  const checkStatusAndNavigate = useCheckStatusAndNavigate("/add-vehicle");

  return (
    <form>
      <Header2>Vehicle Preference</Header2>
      <Flex className="shadow">
        <FlexItem>
          <Header3>{driver?.hasCar ? "I have a car" : "I need a car"}</Header3>

          <Paragraph>
            {driver?.hasCar
              ? "Good, let's get you started. Please note that your vehicle must be 2004 or newer for Bosscab economy, 2008 or newer for Bosscab premium and 2013 or newer for Bosscab VIP. Your vehicle should also have a minimum of four doors and five seatbelts. "
              : "Please provide your information to rent an affordable  and insured vehicle to start your driving journey"}
          </Paragraph>

          <SecondaryButton
            inverse
            justify="flex-start"
            text="Add your vehicle"
            onClick={checkStatusAndNavigate}
          />
        </FlexItem>
        <FlexItem flex className="car">
          <Car />
        </FlexItem>
      </Flex>
    </form>
  );
}

export default VehiclePreference;
