import React from "react";
import styled from "styled-components";

const SecBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => justify};
  margin: ${({ marginY }) => marginY} 0;
  width: 100%;
  button {
    background: ${({ theme, inverse }) =>
      inverse ? theme.colors.primary : theme.colors.white};
    border: 0.063rem solid
      ${({ theme, inverse }) =>
        inverse ? theme.colors.white : theme.colors.primary};
    color: ${({ theme, inverse }) =>
      inverse ? theme.colors.white : theme.colors.primary};
    border-radius: 3.125rem;
    /* width: 19.313rem; */
    /* height: 3.375rem; */
    min-width: 8.563rem;
    padding: 0.75rem 2.125rem;
    height: 42px;
    font-weight: 400;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover,
    &:focus,
    &:active {
      background: ${({ theme, inverse }) =>
        inverse ? theme.colors.white : theme.colors.primary};
      color: ${({ theme, inverse }) =>
        inverse ? theme.colors.black : theme.colors.white};
    }
  }
`;

function SecondaryButton({
  inverse = false,
  onClick,
  loading = false,
  disabled = false,
  text = "Insert Text",
  justify = "center",
  marginY = "1.875rem",
}) {
  return (
    <SecBtn inverse={inverse} marginY={marginY} justify={justify}>
      <button onClick={onClick} disabled={disabled}>
        {text}
      </button>
    </SecBtn>
  );
}

export default SecondaryButton;
