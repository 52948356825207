import React from "react";

// import FAQ from "../pages/shared/faq/faq";

/* This is importing the home page. */
import Home from "../pages/Home";

//Protected Routes
import Nin from "../pages/Nin";
import Questionnaire from "../pages/Questionnaire";
import VehiclePreference from "../pages/VehiclePreference";
import AddVehicle from "../pages/withCar/AddVehicle";
import LocationPref from "../pages/withCar/LocationPref";
import VerifyOtp from "../pages/auth/VerifyOtp";
import DocumentUpload from "../pages/DocumentUpload";
import DriverPhoto from "../pages/DriverPhoto";
import WelcomeBack from "../pages/WelcomeBack";

import CheckAuth from "./CheckAuth.util";
import { CheckLayout } from "../components/Layout";
import CompleteReg from "../pages/CompleteReg";
import GroupKYC from "../pages/group-kyc/GroupKYC";
import Success from "../pages/group-kyc/Success";
import Error from "../pages/group-kyc/Error";

import ForgotPassword from "../pages/auth/ForgotPassword";
import VerifyPassword from "../pages/auth/VerifyPassword";
import NewPassword from "../pages/auth/NewPassword";

/* This is importing the pages that are used in the auth routes. */
const Signup = React.lazy(() => import("../pages/auth/Signup"));
const Login = React.lazy(() => import("../pages/auth/Login"));

const authRoutes = [
  { path: "/signup", element: <Signup /> },
  { path: "/login", element: <Login /> },
  { path: "/verify", element: <VerifyOtp /> },
  { path: "/forgot-password", element: <ForgotPassword /> },
  { path: "/password-verification", element: <VerifyPassword /> },
  { path: "/reset-password", element: <NewPassword /> },
  // { path: "/reg-completed", element: <RegCompleted /> },
];

const indexRoutes = [
  { path: "/", element: <Home /> },
  {
    path: "/group-kyc",
    element: <GroupKYC />,
  },
  {
    path: "/group-kyc/success",
    element: <Success />,
  },
  {
    path: "/group-kyc/error",
    element: <Error />,
  },
  // { path: "/faqs", element: <FAQ /> },
  // {path: "/completed-reg", element: RegCompleted}
];

const PRM = {
  Nin: CheckLayout(CheckAuth(Nin)),
  Questionnaire: CheckLayout(CheckAuth(Questionnaire)),
  VehiclePreference: CheckLayout(CheckAuth(VehiclePreference)),
  AddVehicle: CheckLayout(CheckAuth(AddVehicle)),
  LocationPref: CheckLayout(CheckAuth(LocationPref)),
  DocumentUpload: CheckLayout(CheckAuth(DocumentUpload)),
  DriverPhoto: CheckLayout(CheckAuth(DriverPhoto)),
  WelcomeBack: CheckAuth(WelcomeBack),
  CompleteReg: CheckAuth(CompleteReg),
};

const protectedRoutes = [
  {
    path: "/location-preference",
    element: <PRM.LocationPref />,
  },
  {
    path: "/questionnaire",
    element: <PRM.Questionnaire />,
  },
  {
    path: "/vehicle-preference",
    element: <PRM.VehiclePreference />,
  },
  { path: "/add-vehicle", element: <PRM.AddVehicle /> },
  { path: "/nin", element: <PRM.Nin /> },
  { path: "/document-upload", element: <PRM.DocumentUpload /> },
  { path: "/driver-photo", element: <PRM.DriverPhoto /> },
  { path: "/welcome-back", element: <PRM.WelcomeBack /> },
  { path: "/completed-registration", element: <PRM.CompleteReg /> },
];

export { authRoutes, indexRoutes, protectedRoutes };
