import React from "react";
import { Paragraph, Header1, Header3, Header2 } from "../styles/text.style";
import Navbar from "../components/Navbar";
import styled from "styled-components";

const NF = styled.div`
  padding-top: 12px;
  main {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .blue {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 40%;
    z-index: -99;
    background: ${(props) => props.theme.colors.primary};
  }
  .shadow {
    background: ${(props) => props.theme.colors.white};
    position: relative;
    display: flex;
    align-items: center;
    margin: 20px 50px;
    gap: 20px;
    padding: 50px;
    box-shadow: 0px 8px 33px 1px rgba(0, 5, 40, 0.05);
    border-radius: 17px;

    .circle {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background: #ebedf8;
      opacity: 0.4;
      width: 260px;
      height: 260px;
      border-radius: 50%;
    }

    .content {
      display: flex;
      flex-direction: column;

      align-items: center;
      justify-content: center;
      text-align: center;
      max-width: 500px;
    }
  }
`;

function NotFound() {
  return (
    <NF>
      <Navbar inverse />
      <main>
        <Header2 inverse>Oops</Header2>
        <div className="blue"></div>
        <div className="shadow">
          <div className="circle">
            <Header1 marginY="0">404</Header1>
            <Paragraph marginY="0">Page not found</Paragraph>
          </div>
          <div className="content">
            <Header3 style={{ color: "hsla(232, 75%, 25%, 1)" }}>
              PAGE NOT FOUND ON SERVER
            </Header3>
            <Paragraph align="center">
              Lorem ipsum dolor sit amet, consectetur adipscing elit. Duis
              molestie nisi ipsum, facilisis mauris vehicula sit amet.
            </Paragraph>
            <Paragraph
              style={{ color: "hsla(232, 75%, 25%, 1)" }}
              as="a"
              href="/login"
            >
              Home
            </Paragraph>
          </div>
        </div>
      </main>
    </NF>
  );
}

export default NotFound;
