import React from "react";
import { Navigate } from "react-router-dom";
import getAuthToken from "./getAuthToken";

const CheckAuth = Component => {
  class CheckAuth extends React.Component {
    loggedIn = getAuthToken();

    render() {
      return this.loggedIn ? <Component /> : <Navigate to="/login" />;
    }
  }
  return CheckAuth;
};

export default CheckAuth;
