export const getCarStatus = () => {
  const res = JSON.parse(localStorage.getItem("driver_persist"));

  const hasCar = res && res.data.hasCar;

  if (hasCar === undefined || hasCar === null) {
    return null;
  }

  return hasCar;
};
