import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { StoreContext } from "../store/context/store.context";

const useCheckStatusAndNavigate = routes => {
  const { driverStore } = useContext(StoreContext);
  const navigate = useNavigate();

  const hasCar = driverStore.getDriverData.hasCar;

  if (Array.isArray(routes)) {
    if (routes.length !== 2) {
      throw new Error("routeArr length must be exactly 2");
    }
    return hasCar ? () => navigate(routes[1]) : () => navigate(routes[0]);
  } else if (typeof routes === "string") {
    return () => navigate(routes);
  } else {
    throw new Error("routeArr must be a string or an array");
  }
};

export default useCheckStatusAndNavigate;
