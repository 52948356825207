import { Fragment } from "react";
import { memo, useCallback } from "react";
import styled from "styled-components";
import { ReactComponent as Error } from "../../assets/svg/error.svg";

const Floating = styled.div`
  .floating-group {
    position: relative;
    max-width: 39.563rem;
    margin-top: 0.25rem;
    display: flex;
    align-items: flex-end;
    height: 3.5rem;
    /* background: green; */

    width: 100%;

    /* overflow-x: hidden; */

    input {
      background: ${({ theme, inverse }) =>
        inverse ? theme.colors.primary : theme.colors.white};
      font-size: 1rem;
      padding: 0.625rem 0.625rem 0.5rem 0.313rem;
      display: block;
      width: 100%;
      color: ${({ theme, inverse }) =>
        inverse ? theme.colors.white : theme.colors.black};
      border: none;
      border-radius: 0;
      border-bottom: 0.063rem solid
        ${({ theme, inverse }) =>
          inverse ? theme.colors.white : theme.colors.black};
      /* margin: .625rem 0; */

      @media screen and (min-width: 48rem) {
        font-size: 1rem;
      }

      &:focus {
        outline: none;
        background: transparent;
      }

      &:focus ~ label,
      &:not(:empty) ~ label {
        top: 0rem;
        opacity: 0.7;

        font-size: 0.813rem;
        color: ${({ theme, inverse }) =>
          inverse ? theme.colors.white : theme.colors.black};
      }
    }

    /* input:-internal-autofill-selected {
      appearance: none !important;
      background-image: none !important;
      background-color: ${({ theme, inverse }) =>
      inverse ? theme.colors.white : theme.colors.black} !important;
      color: ${({ theme, inverse }) =>
      inverse ? theme.colors.white : theme.colors.black} !important;
    } */

    input[type="password"] {
      letter-spacing: 0.3em;
    }

    label {
      color: ${({ theme, inverse }) =>
        inverse ? theme.colors.white : theme.colors.black};
      font-size: 1rem;
      font-weight: normal;
      position: absolute;
      pointer-events: none;
      left: 0.313rem;
      top: 1.5rem;
      transition: 300ms ease all;

      &.shrink {
        top: 0rem;
        opacity: 0.7;
        font-size: 0.813rem;
        color: ${({ theme, inverse }) =>
          inverse ? theme.colors.white : theme.colors.black};
      }
    }
  }

  & > span {
    svg {
      height: 1rem;
      width: 1rem;
    }
    margin-top: 0.25rem;
    height: 1rem;
    /* background: yellow; */
    visibility: hidden;
    font-size: 0.75rem;
    color: #ff0000;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    visibility: hidden;

    &.error {
      visibility: visible;
    }
  }
`;

const Input = memo(
  ({
    type = "text",
    label = "Default LAbel",
    value,
    name,
    error,
    pattern,
    onChange,
    autoComplete,
    required,
    inverse,
  }) => {
    const handleChange = useCallback(
      e => {
        let { value } = e.target;
        onChange(name, value);
      },
      [onChange, name]
    );

    return (
      <Floating inverse={inverse}>
        <div className="floating-group">
          <input
            type={type}
            value={value}
            name={name}
            onChange={handleChange}
            autoComplete={autoComplete}
            required={required}
          />
          <label className={value.length > 0 ? "shrink" : undefined}>
            {label}
          </label>
        </div>
        <span className={!!error ? "error" : undefined}>
          {error &&
            error?.map((err, i) => (
              <Fragment key={i}>
                <Error /> {err}
              </Fragment>
            ))}
        </span>
      </Floating>
    );
  }
);

export default Input;
