import { createContext } from "react";
import { AuthService } from "../../service/auth.service";

import { AuthStore } from "../auth.store";
import { DriverStore } from "../driver.store";

const authService = new AuthService();
const authStore = new AuthStore(authService);
const driverStore = new DriverStore();

export const StoreContext = createContext({
  authStore,
  driverStore,
});

export const StoreProvider = ({ children }) => {
  return (
    <StoreContext.Provider value={{ authStore, driverStore }}>
      {children}
    </StoreContext.Provider>
  );
};
