import { useState, useCallback } from "react";
import { mergeAll } from "ramda";

const useYupValidation = (schema, setValueFunction, formValues) => {
  const [errors, setErrors] = useState([]);
  const [isValidating, setIsValidating] = useState(false);

  const onFieldChange = useCallback(
    (fieldName, value) => {
      setValueFunction(prevValues => ({ ...prevValues, [fieldName]: value }));
    },
    [setValueFunction]
  );

  const onSubmit = useCallback(
    (event, successCallback) => {
      event.preventDefault();

      if (!isValidating) setIsValidating(true);

      schema
        .validate(formValues, { abortEarly: false })
        .then(() => {
          setErrors({});
          successCallback(formValues);
        })
        .catch(err => {
          if (err.name === "ValidationError") {
            const errs = mergeAll(
              err.inner.flatMap(e => ({ [e.path]: e.errors }))
            );
            setErrors(errs);
          }
        });
    },
    [isValidating, schema, formValues]
  );

  const reValidate = () => {
    if (!isValidating) return;

    try {
      schema.validateSync(formValues, { abortEarly: false });
      setErrors({});
      return true;
    } catch (err) {
      if (err.name === "ValidationError") {
        const errs = mergeAll(err.inner.flatMap(e => ({ [e.path]: e.errors })));
        setErrors(errs);
        return false;
      }
    }
  };

  return {
    isValidating,
    errors,
    onSubmit,
    onFieldChange,
    reValidate,
  };
};

export default useYupValidation;
