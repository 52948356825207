import { RadioGroup } from "react-radio-input";
import styled from "styled-components";
import { Radio, useRadioGroup } from "react-radio-input";

export const RadioGroupContainer = styled(RadioGroup)`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media screen and (min-width: 48rem) {
    flex-direction: row;
  }
`;

const Label = styled.label(
  ({ isSelected, theme }) => `
  width: 137px;
  height: 42px;
  font-weight: 400;
  font-size: .875rem;
  border: ${isSelected ? "none" : ".063rem solid " + theme.colors.white};
  background: ${isSelected ? theme.colors.white : "none"};
box-sizing: border-box;
user-select: none;
cursor: pointer;
border-radius: 1.875rem;
display: flex;
align-items: center;
justify-content: center;
transition: all 0.2s ease-in-out;

@media screen and (min-width: 48rem) {
  width: 137px;
height: 42px;
}

& span {
  display: block;
  font-weight: 400;
font-size: .875rem;
line-height: 1.688rem;
color: ${isSelected ? theme.colors.black : theme.colors.white};
}
`
);

const RadioElement = styled(Radio)`
  display: none;
`;

function RadioButton({ value = "", label = "" }) {
  const { selectedValue } = useRadioGroup();

  const isSelected = value === selectedValue;

  return (
    <Label isSelected={isSelected}>
      <RadioElement value={value} />
      <span>{label}</span>
    </Label>
  );
}

export default RadioButton;
