/* eslint-disable no-unused-vars */
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { MainButton } from "../../components/Button";
import { Input } from "../../components/InputFields";

import { Header2, Paragraph } from "../../styles/text.style";
import * as yup from "yup";
import useYupValidation from "../../utils/useYupValidation.hook";
import axios from "axios";
// import Spinner from "../../components/Spinner";
import { useNavigate } from "react-router-dom";
import Radio, { RadioGroupContainer } from "../../components/InputFields/Radio";

function GroupKYC() {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    businessName: "",
    businessPhoneNumber: "",
    businessAddress: "",
    // tinNumber: "12",
    cacCert: "k",
    country: "",
    state: "",
    cityName: "",
    votersCard: "",
    internationalPassport: "",
    driversLicence: "",
    jobTitle: "",
    fullLegalName: "",
    dateOfBirth: "2022-07-01",
    businessEmail: "",
    companyRole: "",
    homeAddress: "",
    phoneNumber: "",
    countryId: "2",
    meansOfIdentification: "NIN",
    nin: "",
  });

  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();

  let groupId = searchParams.get("groupId");
  // let groupId = "208113a3-c7b0-4271-aafc-beb8d74cc8bf";
  let token = searchParams.get("token");
  // let token =
  //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJlYTg2OGEzMC1lZGEzLTExZWItYmE1OS0wOTYzZWJiMWYwMDUiLCJyb2xlIjoiVXNlciIsImNvbXBhbnlJZCI6MSwiaWF0IjoxNjU0ODM0OTc5fQ.blj13RTlDnWFesDcF6fU9CgKEkwYSjGEJEFo6_mxHow";

  const formSchema = yup.object().shape({
    businessName: yup.string().required("Business name is required"),
    businessPhoneNumber: yup
      .string()
      .min(9)
      .required("Business phone number is required"),
    businessAddress: yup.string().required("Business address is required"),
    // tinNumber: yup.string().required("TIN is required"),
    // CAC: "",
    country: yup.string().required("Country is required"),
    state: yup.string().required("State is required"),
    cityName: yup.string().required("City name is required"),
    votersCard: yup.string(),
    driversLicence: yup.string(),
    internationalPassport: yup.string(),
    fullLegalName: yup.string().required("Full legal name is required"),
    dateOfBirth: yup.string().required("Date of birth is required"),
    jobTitle: yup.string().required("Job title is required"),
    businessEmail: yup.string().required("Business email address is required"),
    companyRole: yup.string().required("Job title is required"),
    homeAddress: yup.string().required("Home address is required"),
    phoneNumber: yup.string().required("Phone number is required"),
    meansOfIdentification: yup
      .string()
      .required("Form of identification is required"),
    nin: yup.string(),
  });

  const { onSubmit, onFieldChange, errors, reValidate } = useYupValidation(
    formSchema,
    setValues,
    values
  );

  let config = useMemo(() => {
    return {
      method: "get",
      url: `${process.env.REACT_APP_BOSSCAB_API_URL}/users/groups/${groupId}`,
      headers: {
        Authorization: token,
      },
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const meansMap = [
    "Drivers Licence",
    "NIN",
    "Voters Card",
    "International Passport",
  ];

  // useEffect(() => {
  //   if (!token || !groupId) return setLoaded(false);
  //   axios(config)
  //     .then(response => {
  //       setValues(prev => ({
  //         ...prev,
  //         ...response.data.data,
  //         ...response.data.data?.kycInfo,
  //         // tinNumber: response.data.data?.kycInfo.tinNumber
  //         //   ? String(response.data.data?.kycInfo.tinNumber)
  //         //   : values.tinNumber,
  //       }));
  //       setLoaded(true);
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });

  //   //eslint-disable-next-line
  // }, []);

  // useEffect(() => {
  //   alert(`${groupId}, work bitch~~~~~ ${token}`, "work bitch");
  // }, []);
  useEffect(() => {
    reValidate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const handleSubmit = () => {
    if (!values) return values;
    setLoading(true);
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BOSSCAB_API_URL}/users/groups/${groupId}/kyc`,
      headers: {
        Authorization: token,
      },
      data: values,
    };

    console.log(values);

    axios(config)
      .then((response) => {
        const res = response.data;
        setLoading(false);
        if (!res.status || !res.data.updated)
          return navigate("/group-kyc/error");
        navigate("/group-kyc/success");
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        navigate("/group-kyc/error");
      });
  };

  return (
    <div
      style={{
        margin: "2rem auto",
        padding: "20px 1rem",
        maxWidth: "550px",
      }}
    >
      {/* {!loaded ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "500px",
            width: "100%",
          }}
        >
          <Spinner />
        </div>
      ) : ( */}
      <form onSubmit={(e) => onSubmit(e, handleSubmit)}>
        <Header2>Business Details</Header2>
        <Input
          label="Business name"
          type="text"
          name="businessName"
          onChange={onFieldChange}
          value={values.businessName}
          error={errors.businessName}
          marginY="12px"
        />
        <Input
          label="Business address"
          type="text"
          name="businessAddress"
          onChange={onFieldChange}
          // value={values.businessAddress}
          value={groupId}
          error={errors.businessAddress}
          marginY="12px"
        />
        <Input
          label="Business phone number"
          type="text"
          name="businessPhoneNumber"
          onChange={onFieldChange}
          // value={values.businessPhoneNumber}
          value={token}
          error={errors.businessPhoneNumber}
          marginY="12px"
        />
        {/* <Input
            label="TIN number"
            type="text"
            name="tinNumber"
            onChange={onFieldChange}
            value={values.tinNumber && String(values.tinNumber)}
            error={errors.tinNumber}
            marginY="12px"
          /> */}
        <Input
          label="Country"
          type="text"
          name="country"
          onChange={onFieldChange}
          value={values.country}
          error={errors.country}
          marginY="12px"
        />
        {/* select in  country */}
        <Input
          label="State"
          type="text"
          name="state"
          onChange={onFieldChange}
          value={values.state}
          error={errors.state}
          marginY="12px"
        />

        <Input
          label="Job Title"
          type="text"
          name="jobTitle"
          onChange={onFieldChange}
          value={values.jobTitle}
          error={errors.jobTitle}
          marginY="12px"
        />
        <Header2 marginY="32px 0 20px">Business Representative</Header2>

        <RadioGroupContainer
          name="meansOfIdentification"
          onChange={(selectedValue) =>
            setValues((prev) => ({
              ...prev,
              meansOfIdentification: selectedValue,
            }))
          }
          selectedValue={values.meansOfIdentification}
          component="div"
          style={{
            display: "flex",
            marginBottom: "20px",
          }}
        >
          <Paragraph variant="body_medium">Form of Identification</Paragraph>
          {meansMap.map((means, idx) => (
            <Radio label={means} value={means} key={idx} />
          ))}
        </RadioGroupContainer>

        {values.meansOfIdentification === "Drivers Licence" && (
          <Input
            label="Drivers Licence"
            type="text"
            name="drviersLicence"
            onChange={onFieldChange}
            value={values.driversLicence}
            error={errors.driversLicence}
            marginY="12px"
          />
        )}

        {values.meansOfIdentification === "NIN" && (
          <Input
            label="National Identification Number (NIN)"
            type="text"
            name="nin"
            onChange={onFieldChange}
            value={values.nin}
            error={errors.nin}
            marginY="12px"
          />
        )}

        {values.meansOfIdentification === "Voters Card" && (
          <Input
            label="Voters Card"
            type="text"
            name="votersCard"
            onChange={onFieldChange}
            value={values.votersCard}
            error={errors.votersCard}
            marginY="12px"
          />
        )}

        {values.meansOfIdentification === "International Passport" && (
          <Input
            label="International Passport"
            type="text"
            name="internationalPassport"
            onChange={onFieldChange}
            value={values.internationalPassport}
            error={errors.internationalPassport}
            marginY="12px"
          />
        )}

        <Input
          label="Full legal name"
          type="text"
          name="fullLegalName"
          onChange={onFieldChange}
          value={values.fullLegalName}
          error={errors.fullLegalName}
          marginY="12px"
        />
        <Input
          label="Date of birth"
          type="date"
          name="dateOfBirth"
          onChange={onFieldChange}
          value={values.dateOfBirth.slice(0, 10)}
          error={errors.dateOfBirth}
          marginY="12px"
        />
        <Input
          label="City"
          type="text"
          name="cityName"
          onChange={onFieldChange}
          value={values.cityName}
          error={errors.cityName}
          marginY="12px"
        />
        <Input
          label="Business email address"
          type="email"
          name="businessEmail"
          onChange={onFieldChange}
          value={values.businessEmail}
          error={errors.businessEmail}
          marginY="12px"
        />
        <Input
          label="Company Role"
          type="text"
          name="companyRole"
          onChange={onFieldChange}
          value={values.companyRole}
          error={errors.companyRole}
          marginY="12px"
        />
        <Input
          label="Home address"
          type="text"
          name="homeAddress"
          onChange={onFieldChange}
          value={values.homeAddress}
          error={errors.homeAddress}
          marginY="12px"
        />
        <Input
          label="Phone number"
          type="text"
          name="phoneNumber"
          onChange={onFieldChange}
          value={values.phoneNumber}
          error={errors.phoneNumber}
          marginY="12px"
        />
        <MainButton text="Complete" loading={loading} />
      </form>
      {/* )} */}
    </div>
  );
}

export default GroupKYC;
