import { useState, useEffect } from "react";
import styled from "styled-components";
import { Header2 } from "../styles/text.style";
import Accordion from "./Accordion";

const FQ = styled.div`
  margin: 40px 0;
`;

function Faq() {
  const [data, setData] = useState([]);

  const api = "https://bosscabapis.nugitech.com/faqs/";

  useEffect(() => {
    fetch(api)
      .then(data => {
        if (!data.ok) {
          throw Error(data.status);
        }
        return data.json();
      })
      .then(res => {
        setData(res.data.rows);
      })
      .catch(e => {
        console.log(e);
      });
    return () => {};
  }, []);

  return (
    <FQ>
      <Header2>Frequently Asked Questions</Header2>

      <div>
        {data.map((row, i) => (
          <Accordion title={row.question} content={row.answer} key={i} />
        ))}
      </div>
    </FQ>
  );
}

export default Faq;
