import { makeAutoObservable } from "mobx";
import { clearPersistedStore, makePersistable } from "mobx-persist-store";

export class AuthStore {
  authenticated = false;

  constructor(authService) {
    makeAutoObservable(this);
    this.authService = authService;
    makePersistable(this, {
      name: "driver_auth_persist",
      properties: ["data"],
      storage: window.localStorage,
    });
  }

  data = {};

  get getAuthData() {
    return this.data;
  }

  set setAuthData(value) {
    this.data = { ...this.data, ...value };
  }

  /**
   * @param {boolean} value
   */
  set setAuthenticated(value) {
    this.authenticated = value;
  }

  get getAuthenticated() {
    return this.authenticated;
  }

  async login(phone, password) {
    const response = await this.authService.login(phone, password);
    // localStorage.setItem("driver_token", response.data.token);
    this.setAuthenticated = true;
    return response.data;
  }

  async register(registerRequest) {
    const response = await this.authService.register(registerRequest);
    // localStorage.setItem("token", response.data.token);
    // this.setAuthenticated = true;
    return response.data;

    // this.setAuthenticated = false;
  }

  async sendOTP(phone) {
    const response = await this.authService.sendOTP(phone);
    return response;
  }

  async verifyOTP(otp, phone) {
    const response = await this.authService.verifyOTP(otp, phone);
    return response;
  }

  async clearStoredData() {
    await clearPersistedStore(this);
  }
}
