import React from "react";
import styled from "styled-components";
import { ReactComponent as OK } from "../assets/svg/ok.svg";
import { Header2, Paragraph } from "../styles/text.style";

const Reg = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;

    align-items: center;
    padding: 28px;
    background: #ffffff;
    box-shadow: 0px 8px 33px 1px rgba(0, 0, 0, 0.05);
  }
`;

function CompleteReg() {
  return (
    <Reg>
      <div>
        <OK />
        <Header2 align="center">Registration completed successfully</Header2>
        <Paragraph align="center">
          Please check your registered email for email verification
        </Paragraph>
      </div>
    </Reg>
  );
}

export default CompleteReg;
