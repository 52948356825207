import React from "react";
import styled from "styled-components";
import { Paragraph } from "../styles/text.style";

const PR = styled.div`
  position: absolute;
  /* top: 100%; */
  left: 50%;
  margin-top: 20%;
  transform: translateX(-50%);

  @media only screen and (min-width: 768px) {
    margin-top: 10%;
    /* top: 50%; */
  }
`;

const ProgressItem = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  span {
    width: 10px;
    height: 10px;
    background: ${({ theme }) => theme.colors.white};
    border-radius: 50%;
  }
`;

function Progress() {
  return (
    <PR>
      <ProgressItem>
        <span></span>
        <Paragraph inverse>Vehicle Information</Paragraph>
      </ProgressItem>
      <ProgressItem>
        <span></span>
        <Paragraph inverse>Driver Information</Paragraph>
      </ProgressItem>
      <ProgressItem>
        <span></span>
        <Paragraph inverse>Documents Upload </Paragraph>
      </ProgressItem>
    </PR>
  );
}

export default Progress;
