import React, { useState } from "react";
import { MainButton } from "../components/Button";
import ImageUploader from "../components/ImageUploader";

import getAuthToken from "../utils/getAuthToken";

// import toast from "react-hot-toast";
import { Header2, Header3, Paragraph } from "../styles/text.style";
// import useCheckStatusAndNavigate from "../utils/useCheckStatusAndNavigate.hook";

function DocumentUpload() {
  // const checkStatusAndNavigate = useCheckStatusAndNavigate("/driver-photo");

  const [licenseFront, setLicenseFront] = useState(null);
  const [licenseBack, setLicenseBack] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch(
      `${process.env.REACT_APP_BOSSCAB_API_URL}/drivers/prospective/licence`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
        body: JSON.stringify({
          licenseFront: licenseFront,
          licenseBack: licenseBack,
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        // setManufacturers(data);
        console.log(data, "how nau");
      })
      .catch((err) => console.log(err));
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   checkStatusAndNavigate();
  // };

  return (
    <form className="license" onSubmit={handleSubmit}>
      <Header2>Documents Upload</Header2>
      <Header3>Driver’s License</Header3>
      <Paragraph>
        We need your driver's license to ascertain your road worthiness. Your
        safety and that of the riders are top priority to us.
      </Paragraph>
      <div>
        <Paragraph>Upload the front view of your License</Paragraph>
        <ImageUploader setImg={setLicenseFront} />
      </div>
      <div>
        <Paragraph>Upload the back view of your License</Paragraph>
        <ImageUploader setImg={setLicenseBack} />
      </div>
      <div className="instructions">
        <Paragraph>For easy recognition, ensure your photo is : </Paragraph>
        <ul style={{ listStyle: "none" }}>
          <li>
            <Paragraph> Not blurry</Paragraph>
          </li>
          <li>
            <Paragraph>Not more than 5mb in size</Paragraph>
          </li>
          <li>
            <Paragraph>Either jpg, jpeg or png</Paragraph>
          </li>
        </ul>
      </div>
      <MainButton text="Next" />
    </form>
  );
}

export default DocumentUpload;
