import axios from "axios";
import getAuthToken from "./getAuthToken";

export const BosscabClient = axios.create({
  baseURL: process.env.REACT_APP_BOSSCAB_API_URL,
});

export const get = (url, token, params) => {
  if (!url) return null;
  return {
    url: url,
    method: "get", // default
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    params,
  };
};

export const post = (url, data, auth = getAuthToken()) => {
  return {
    url: url,
    method: "post", // default
    headers: {
      "Content-Type": "application/json",
      Authorization: auth,
    },
    data: data,
  };
};

export const put = (url, data) => {
  return {
    url: url,
    method: "put", // default
    headers: {
      "Content-Type": "application/json",
      Authorization: getAuthToken(),
    },
    data: data,
  };
};

export const axiosConfig = (url, data, method, token) => {
  return {
    url: url,
    method, // default
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };
};

export const file = (url, data, method) => {
  return {
    url: url,
    data: data,
    method, // default
    headers: {
      "Content-Type": "application/json",
      Authorization: getAuthToken(),
    },
    responseType: "blob",
  };
};
