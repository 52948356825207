import { post, BosscabClient } from "../utils/axios.config";
import randHex from "../utils/randHex";
const md5 = require("md5");

const companyId = process.env.REACT_APP_COMPANY_ID;

export class AuthService {
  /**
   * It takes a loginRequest object as an argument, makes a post request to the server, and returns the
   * response
   * @param loginRequest - This is the object that contains the login credentials.
   * @returns The response from the server.
   */
  async login(phone, password) {
    const response = await BosscabClient(
      post("/drivers/prospective/login", { phone, password, companyId }, "")
    );

    return response.data;
  }

  /**
   * It registers a new driver.
   * @param registerRequest - This is the object that contains the data that you want to send to the
   * server.
   * @returns The response from the server.
   */
  async register(registerRequest) {
    const response = await BosscabClient(
      post("/drivers/prospective/", { ...registerRequest, companyId }, "")
    );

    return response.data;
  }

  async sendOTP(phone) {
    const salt = randHex(6);
    const md5token = md5(salt + process.env.REACT_APP_FORCE_KEY);

    const data = {
      companyId,
      mobileNumber: phone,
      uniqueId: randHex(6),
      service: "checkMobi",
      salt,
      hasKey: randHex(8),
      userType: 2,
    };
    const response = await BosscabClient(post("/sms/send", data, md5token));
    return response.data;
  }

  async verifyOTP(otp, phone) {
    const salt = randHex(6);
    const md5token = md5(salt + process.env.REACT_APP_FORCE_KEY);

    const data = {
      companyId,
      mobileNumber: phone,
      service: "checkMobi",
      salt,
      otp,
    };
    const response = await BosscabClient(post("/sms/verify", data, md5token));

    return response.data;
  }
}
