import React from "react";
import { useNavigate } from "react-router-dom";
// import { ReactComponent as Ok } from "../../assets/svg/ok.svg";
import { SecondaryButton } from "../../components/Button";

function Error() {
  const navigate = useNavigate();
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        rowGap: "10px",
      }}
    >
      <img src="" alt="error-icon" />
      <small>An error occurred.</small>
      <SecondaryButton text="Go back" onClick={() => navigate(-1)} />
    </div>
  );
}

export default Error;
