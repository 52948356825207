/* eslint-disable no-unused-vars */
import { useCallback, useContext, useState } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
// import styled from "styled-components";
// import toast from "react-hot-toast";
import { WithoutLayout } from "../../components/Layout";
import { Header1, Header3, Paragraph } from "../../styles/text.style";
import { MainButton } from "../../components/Button";
import { StoreContext } from "../../store/context/store.context";
import { Input } from "../../components/InputFields";

function VerifyPassword() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const { authStore } = useContext(StoreContext);

  const handleSubmit = useCallback(async () => {
    if (!values) return null;
  }, [values]);

  return (
    <WithoutLayout>
      <Header1 align="center">Verification</Header1>

      <Paragraph align="center">
        Enter the verification code we just sent to your email address to reset
        your password
      </Paragraph>
      <form onSubmit={handleSubmit}>
        <Input />
        <Input />
        <Header3>Password must contain minimum of 8 characters</Header3>
        <MainButton loading={loading} text="Next" />
      </form>
    </WithoutLayout>
  );
}

export default observer(VerifyPassword);
