const resumeMap = {
  home: "/",
  "vehicle-preference": "/vehicle-preference",
  questionnaire: "/questionnaire",
  "location-preference": "/location-preference",
  "add-vehicle": "/add-vehicle",
  "driver-photo": "/driver-photo",
  nin: "/nin",
  "driver-license": "/driver-license",
};

export const getResumePage = page => {
  return resumeMap[page];
};
