import React, { useEffect } from "react";
import { Radio, RadioGroup } from "react-radio-input";
import styled from "styled-components";
import { MainButton } from "../../components/Button";
import { Header1, Paragraph } from "../../styles/text.style";
import { BosscabClient, get } from "../../utils/axios.config";
import useCheckStatusAndNavigate from "../../utils/useCheckStatusAndNavigate.hook";

const RadioGroupContainer = styled(RadioGroup)`
  display: flex;
  flex-direction: column;
  gap: 16px;

  label {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

function LocationPref() {
  const [preferredLocation, setPreferredLocation] = React.useState("");
  const [cities, setCities] = React.useState([]);
  const checkStatusAndNavigate = useCheckStatusAndNavigate("/questionnaire");

  useEffect(() => {
    BosscabClient(get("/locations")).then((res) =>
      setCities(res.data.data.rows)
    );
  }, []);

  // const handleSubmit = () => {
  //   checkStatusAndNavigate();
  // };

  return (
    <div>
      <Header1>Location Preference.</Header1>
      <Paragraph marginY="12px">Where do you want to drive ?</Paragraph>
      <RadioGroupContainer
        name="location"
        onChange={setPreferredLocation}
        selectedValue={preferredLocation}
        component="div"
      >
        {cities.map((city) => (
          <Paragraph as="label" key={city.id}>
            <Radio value={city.name} />
            <span>{city.name}</span>
          </Paragraph>
        ))}
      </RadioGroupContainer>
      <MainButton
        text="Next"
        justify="flex-start"
        onClick={checkStatusAndNavigate}
      />
    </div>
  );
}

export default LocationPref;
