import ReactSelect from "react-select";

const SelectStyle = {
  valueContainer: (provided, state) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
  }),
  control: (provided, state) => ({
    ...provided,
    width: "100%",

    border: "none",
    outline: "none",
    boxShadow: state.isFocused ? "none" : 0,
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
  }),
  option: (provided, state) => ({
    ...provided,
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    cursor: "pointer",
    zIndex: 1000,
    color: state.isSelected ? "#ffffff" : "rgba(0,0,0,0.9)",
  }),

  singleValue: (provided, state) => ({
    ...provided,
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",

    color: "#000000",
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: "none",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",

    color: "#000",
  }),
};

export default function Select({
  value,
  handleChange,
  onChange,
  options,
  placeholder = "select component",
}) {
  return (
    <ReactSelect
      options={options}
      styles={SelectStyle}
      value={value}
      onChange={onChange}
      onInputChange={handleChange}
      isSearchable={false}
      placeholder={placeholder}
    />
  );
}
