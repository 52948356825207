import styled from "styled-components";

const Grid = styled.div`
  display: grid;
  grid-gap: 12px;
  grid-template-columns: repeat(6, 1fr);
  margin: 24px 0;

  @media screen and (min-width: 768px) {
    gap: ${({ gap }) => (gap ? gap : 12)}px;
    grid-template-columns: repeat(12, 1fr);
  }
`;

const GridItem = styled.div`
  overflow: hidden;
  position: relative;
  grid-column-end: span 6;

  @media screen and (min-width: 768px) {
    /* grid-template-columns: repeat(12, 1fr);
     */
    grid-column-end: span ${({ span }) => span};
  }

  @media screen and (min-width: 1024px) {
    /* grid-template-columns: repeat(12, 1fr);
     */
    grid-column-end: span ${({ span }) => span};
  }

  @media screen and (min-width: 1440px) {
    /* grid-template-columns: repeat(12, 1fr);
     */
    grid-column-end: span ${({ span }) => span};
  }
`;

const Container = styled.div(
  ({ percentage }) => `
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0 auto;
  padding: 0.5rem 1rem;

  // @media only screen and (min-width: 576px) {
  //   width: 540px;
  // }

  @media only screen and (min-width: 768px) {
    width: ${percentage ? "93.75%" : "720px"};
    /* padding: 1rem 0; */
  }

  @media only screen and (min-width: 992px) {
    width: ${percentage ? "96.77%" : "960px"};
  }

  @media only screen and (min-width: 1200px) {
    width: ${percentage ? "95%" : "1140px"};
  }

  @media only screen and (min-width: 1400px) {
    width: ${percentage ? "91.66%" : "1320px"};
  }

`
);

export { Grid, GridItem, Container };
