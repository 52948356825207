import { useState } from "react";
import styled from "styled-components";
import { Paragraph } from "../styles/text.style";

const ACC = styled.div`
  .accordion-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
  }
`;

function Accordion({ title, content }) {
  const [isActive, setIsActive] = useState(false);

  return (
    <ACC style={{ cursor: "pointer" }}>
      <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
        <Paragraph>{title}</Paragraph>

        <Paragraph style={{ cursor: "pointer" }}>
          {isActive ? "-" : "+"}
        </Paragraph>
      </div>
      {isActive && <Paragraph style={{ opacity: 0.5 }}>{content} </Paragraph>}
    </ACC>
  );
}

export default Accordion;
