import { makeAutoObservable } from "mobx";
import { clearPersistedStore, makePersistable } from "mobx-persist-store";

export class DriverStore {
  constructor() {
    makeAutoObservable(this, { autoBind: true });
    makePersistable(this, {
      name: "driver_persist",
      properties: ["data", "status"],
      storage: window.localStorage,
    });
  }

  data = {};

  status = {
    otpVerified: false,
    isLoggedin: false,
    isRegistered: false,
    resumePage: null,
  };

  get getDriverData() {
    return this.data;
  }

  get getDriverStatus() {
    return this.status;
  }

  /**
   * @param {object} value
   */
  set setDriverData(value) {
    this.data = { ...this.data, ...value };
  }

  /**
   * @param {object} value
   */
  set setDriverStatus(value) {
    this.status = { ...this.status, ...value };
  }

  async clearStoredData() {
    await clearPersistedStore(this);
  }
}
