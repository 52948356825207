import React from "react";

import styled from "styled-components";

import { Container } from "../../styles/layout.style";
// import { Back } from "../Button";
import Navbar from "../Navbar";
import Progress from "../Progress";

const WTL = styled.div`
  width: 100%;
  height: 100%;

  main {
    padding-top: 64px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  @media only screen and (min-width: 768px) {
    main {
      padding: 0 3rem;
      max-width: 90%;
      margin: 0 auto;
    }
  }

  @media screen and (min-width: 1024px) {
    display: grid;
    background: ${(props) => props.theme.colors.primary};
    grid-template-columns:
      min(536px, 35%)
      1fr;

    .right {
      background: ${({ theme }) => theme.colors.white};
      border-radius: 30px 0 0 30px;
      position: relative;
      height: 100%;
      width: 100%;

      main {
        position: absolute;
        top: 60px;
        left: 50%;
        transform: translateX(-50%);
        bottom: 40px;
        overflow: hidden;
        overflow-y: auto;
        max-width: min(800px, 90%);
      }
    }
  }

  .left {
    position: relative;
    min-height: 40%;
    background: ${({ theme }) => theme.colors.primary};
  }
`;

function WithLayout({ children }) {
  return (
    <WTL>
      <section className="left">
        <Container percentage>
          <Navbar avatar />
          <Progress />
        </Container>
      </section>
      <section className="right">
        <main>
          <Container percentage>
            {/* <Back /> */}
            {children}
          </Container>
        </main>
      </section>
    </WTL>
  );
}

export default WithLayout;
