import React from "react";
import { MainButton } from "../components/Button";
import ImageUploader from "../components/ImageUploader";
import DriverPhotoExample from "../assets/img/driver-example.png";
import { Header2, Header3, Paragraph } from "../styles/text.style";
import styled from "styled-components";
import useCheckStatusAndNavigate from "../utils/useCheckStatusAndNavigate.hook";

const DPE = styled.div`
  position: relative;
  width: 100%;
  height: 301px;
  overflow: hidden;
  margin: 12px 0;
  @media screen and (min-width: 1024px) {
    height: 496px;
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
  }
`;

function DriverPhoto() {
  const checkStatusAndNavigate = useCheckStatusAndNavigate(
    "/completed-registration"
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    checkStatusAndNavigate();
  };

  return (
    <form className="driver-photo" onSubmit={handleSubmit}>
      <Header2>Driver Photo</Header2>
      <Paragraph>
        Your profile picture will appear on the rider app so riders can identify
        you.
      </Paragraph>

      <div className="example">
        <ImageUploader />

        <Header3 marginY="16px">Example of Driver Photo</Header3>
        <DPE>
          <img src={DriverPhotoExample} alt="example" />
        </DPE>
        <div className="instructions">
          <Paragraph>For easy recognition, ensure your photo is : </Paragraph>
          <ul style={{ listStyle: "none" }}>
            <li>
              <Paragraph> Not blurry</Paragraph>
            </li>
            <li>
              <Paragraph>Not more than 5mb in size</Paragraph>
            </li>
            <li>
              <Paragraph>Either jpg, jpeg or png</Paragraph>
            </li>
          </ul>
        </div>
      </div>

      <MainButton text="Next" />
    </form>
  );
}

export default DriverPhoto;
