import React from "react";
import styled from "styled-components";
import { Container, Grid, GridItem } from "../../styles/layout.style";
import Navbar from "../Navbar";
// import { Back } from "../Button";

const WLT = styled.div`
  position: relative;
  height: 100%;

  main {
    position: absolute;
    top: 2.222rem;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    overflow-y: auto;
    padding-top: 28px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

function WithoutLayout({ children }) {
  return (
    <WLT>
      <Navbar />
      <main>
        {/* <Back /> */}
        <Grid>
          <GridItem span={2} />
          <GridItem span={8}>
            <Container percentage>{children}</Container>
          </GridItem>
          <GridItem span={2} />
        </Grid>
      </main>
    </WLT>
  );
}

export default WithoutLayout;
