import React from "react";
import styled from "styled-components";
import { Container, Grid, GridItem } from "../styles/layout.style";
import { Header2, Header3, Paragraph } from "../styles/text.style";
import { ReactComponent as Boss } from "../assets/svg/boss.svg";
import { ReactComponent as Pay } from "../assets/svg/pay.svg";
import { ReactComponent as Together } from "../assets/svg/together.svg";
import Drive from "../assets/img/driving.png";
import Faq from "./Faq";

const MF = styled.div`
  margin: 4rem 0;
`;

const Im = styled.div`
  position: relative;
  width: 100%;
  height: 311px;
  overflow: hidden;
  margin: 12px 0;
  @media screen and (min-width: 1024px) {
    height: 540px;
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
  }
`;

function MoreInfo() {
  return (
    <MF>
      <Container>
        <Header2>About Bosscab</Header2>
        <Paragraph align="justify">
          For places you want to go, endless opportunities to ride and earn:
          Safety, Comfort, Reliability, and Affordability are the qualities we
          want you to think of when you take a ride with Bosscab. Everyone
          dreams of being a boss, let’s give you that experience while you
          journey with us!
        </Paragraph>
        <Header2 marginY="30px">Why Bosscab?</Header2>
        <Grid gap={40}>
          <GridItem span={4}>
            <Boss />
            <Header3>Be your own boss</Header3>
            <Paragraph align="justify">
              Ride conveniently and in style, whether you're a driver or a
              passenger, it really doesn't matter, you're in control.
            </Paragraph>
          </GridItem>
          <GridItem span={4}>
            <Pay />
            <Header3>Innovative pay</Header3>
            <Paragraph align="justify">
              Pay for your rides by just the push of a button, you don't have to
              worry about limitations associated with traditional payment
              systems. Instant settlement for drivers.
            </Paragraph>
          </GridItem>
          <GridItem span={4}>
            <Together />
            <Header3>Ride together</Header3>
            <Paragraph align="justify">
              You don't have to ride alone, make use of our group ride packages
              with your family, friends or work buddies at pocket friendly rates
              and arrive your destination together, just in time for that
              meeting, lunch or date.
            </Paragraph>
          </GridItem>
        </Grid>
        <Im>
          {" "}
          <img src={Drive} alt="example" />
        </Im>
        {/* <Grid>
          <GridItem span={4}>
            <Pay />
            <Header3>Innovative pay</Header3>
            <Paragraph>
              Pay for your rides by just the push of a button, you don't have to
              worry about limitations associated with traditional payment
              systems. Instant settlement for drivers.
            </Paragraph>
          </GridItem>
          <GridItem span={4}>
            <Together />
            <Header3>Ride together</Header3>
            <Paragraph>
              You don't have to ride alone, make use of our group ride packages
              with your family, friends or work buddies at pocket friendly rates
              and arrive your destination together, just in time for that
              meeting, lunch or date.
            </Paragraph>
          </GridItem>
        </Grid> */}

        <Faq />
      </Container>
    </MF>
  );
}

export default MoreInfo;
