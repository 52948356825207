import styled from "styled-components";
import { ReactComponent as Right } from "../../assets/svg/right.svg";
import Spinner from "../Spinner";

const MBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => justify};
  margin: ${({ marginY }) => marginY} 0;
  width: 100%;

  button {
    /* min-width: 100%; */
    /* max-width: 19.313rem; */
    width: 100%;
    height: 3.375rem;
    background: ${({ inverse, theme }) =>
      inverse ? theme.colors.white : theme.colors.primary};

    border-radius: 3.125rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 2rem;
    padding-right: 0.563rem;
    font-weight: 700;
    font-size: 1.25rem;
    color: ${({ inverse, theme }) =>
      inverse ? theme.colors.primary : theme.colors.white};
    cursor: pointer;

    @media screen and (min-width: 48rem) {
      max-width: 19.313rem;
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.5rem;
      height: 2.5rem;
      background: ${({ inverse, theme }) =>
        inverse ? theme.colors.primary : theme.colors.white};
      border-radius: 50%;

      svg {
        fill: ${({ inverse, theme }) =>
          inverse ? theme.colors.white : theme.colors.primary};
      }
    }
  }
`;

const MainButton = ({
  inverse = false,
  onClick,
  loading = false,
  disabled = false,
  text = "Insert Text",
  justify = "center",
  marginY = "1.875rem",
  className,
}) => {
  return (
    <MBtn
      justify={justify}
      inverse={inverse}
      marginY={marginY}
      className={className}
    >
      <button onClick={onClick} disabled={disabled || loading} type="submit">
        {text}
        <span>{loading ? <Spinner inverse={inverse} /> : <Right />}</span>
      </button>
    </MBtn>
  );
};

export default MainButton;
