import styled from "styled-components";

const Header1 = styled.h1`
  font-weight: 500;
  font-size: 1.556rem;
  text-align: ${({ align }) => align || "left"};
  line-height: 1.944rem;
  margin: ${({ marginY }) => marginY ?? ".444rem"} 0;
  color: ${({ inverse, theme }) =>
    inverse ? theme.colors.white : theme.colors.black};
  @media screen and (min-width: 42.667rem) {
    font-weight: 500;
    font-size: 2.889rem;
    line-height: 4.167rem;
  }
`;

const Header2 = styled.h2`
  font-weight: 500;
  font-size: 1.111rem;
  text-align: ${({ align }) => align || "left"};
  margin: ${({ marginY }) => marginY ?? ".444rem"} 0;
  color: ${({ inverse, theme }) =>
    inverse ? theme.colors.white : theme.colors.black};
  @media screen and (min-width: 42.667rem) {
    font-size: 1.556rem;
    /* line-height: 4.167rem; */
  }
`;

const Header3 = styled.h3`
  font-weight: 500;
  font-size: 1rem;
  text-align: ${({ align }) => align || "left"};
  margin: ${({ marginY }) => marginY ?? ".444rem"} 0;

  color: ${({ inverse, theme }) =>
    inverse ? theme.colors.white : theme.colors.black};
  @media screen and (min-width: 42.667rem) {
    font-size: 1.111rem;
    line-height: 2rem;
  }
`;

const Paragraph = styled.p`
  font-size: 0.778rem;
  font-weight: 400;
  text-align: ${({ align }) => align || "left"};
  margin: ${({ marginY }) => marginY ?? ".444rem"} 0;
  color: ${({ inverse, theme }) =>
    inverse ? theme.colors.white : theme.colors.black};
  @media screen and (min-width: 42.667rem) {
    font-size: 0.833rem;
  }
`;

export { Header1, Header2, Header3, Paragraph };
