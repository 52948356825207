import React from "react";
import NotFound from "../pages/NotFound";
import { Route, Routes } from "react-router-dom";

import { authRoutes, indexRoutes, protectedRoutes } from "./allRoutes.util";

export default function AllRoutes() {
  return (
    <Routes>
      {authRoutes.map(authRoute => (
        <Route
          key={authRoute.path}
          path={authRoute.path}
          element={<React.Suspense>{authRoute.element}</React.Suspense>}
        />
      ))}

      {indexRoutes.map(indexRoute => (
        <Route key={indexRoute.path} {...indexRoute} />
      ))}

      {protectedRoutes.map(route => (
        <Route key={route.path} element={route.element} path={route.path} />
      ))}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
