import { memo } from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from "../assets/svg/logo.svg";
import { ReactComponent as Avatar } from "../assets/svg/avatar.svg";
import { Container } from "../styles/layout.style";

const Nav = styled.nav`
  padding: 1rem 0 0 0;
  height: 45px;
  display: flex;
  align-items: flex-end;

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @media screen and (min-width: 768px) {
    height: 50px;
  }

  & .nav-logo {
    width: 32px;
    height: 32px;
    fill: ${({ inverse, theme }) =>
      inverse ? theme.colors.white : theme.colors.primary};

    @media screen and (min-width: 768px) {
      width: 40px;
      height: 40px;
    }
  }

  & .inner-avatar {
    fill: ${({ theme, inverse }) =>
      inverse ? theme.colors.white : theme.colors.primary};
  }

  & .outer-avatar {
    fill: ${({ theme, inverse }) =>
      inverse ? theme.colors.primary : theme.colors.white};
  }
`;

function Navbar({ inverse, avatar, className }) {
  return (
    <Nav inverse={inverse} className={className}>
      <Container percentage>
        <div className="navbar-logo">
          <Logo className="nav-logo" />
        </div>
        {avatar && (
          <div>
            <Avatar className="inner-avatar outer-avatar" />
          </div>
        )}
      </Container>
    </Nav>
  );
}

export default memo(Navbar);
