import React from "react";
import { ReactComponent as Ok } from "../../assets/svg/ok.svg";

function Success() {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        rowGap: "10px",
      }}
    >
      <Ok />
      <small>Changes applied successfully</small>
    </div>
  );
}

export default Success;
